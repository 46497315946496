import React from 'react'
import { Anchor, Divider } from 'antd'
import Container from '../components/Landing/Container'
import { CalendarOutlined, FundOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import SignupBelt from '../components/Landing/SignupBelt'

const { Link } = Anchor

export default function DKVacationGuidePage({ setCookieValue, data }) {
    const affixContainerStyle: React.CSSProperties = isMobile
        ? {}
        : { display: 'grid', gridTemplateColumns: '1fr 6fr 1fr', marginLeft: 10, marginRight: 10 }

    return (
        <Container setCookieValue={setCookieValue} pathName="42-dages-reglen">
            <SEO title="42-dages-reglen Guide" />
            <span style={affixContainerStyle}>
                {!isMobile && (
                    <Anchor offsetTop={100} targetOffset={156}>
                        <Link href="#guide-title" title="42-dages-reglen" />
                        <Link href="#guide-start" title="“ferie el.lign.” i DK">
                            <Link href="#step-1" title="1. Kalenderen" />
                            <Link href="#step-2" title="2. Ankomst til Danmark" />
                            <Link href="#step-3" title="3. Dag-menu" />
                            <Link href="#step-4" title="4. Afrejse fra Danmark" />
                            <Link href="#step-5" title="5. Færdig" />
                            <Link href="#step-6" title="6. Rediger ferie" />
                            <Link href="#step-7" title="7. Grafen" />
                        </Link>
                    </Anchor>
                )}
                <div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 10,
                        }}
                        id="guide-title"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            42-dages-reglen
                        </h1>
                        <p className="landing-guide-header-p">
                            42-dages-reglen er gældende under dit udlandsophold. Under et
                            udlandsophold er det tilladt at være på{' '}
                            <span style={{ fontWeight: 'bold' }}>“ferie eller lignende”</span> i
                            Danmark i op til 42 dage pr. løbende 6 måneder bagud. Bemærk, udrejse og
                            hjemrejse dage (start og slut dato), for et udlandsophold, ikke tælles
                            med i 42-dages-reglen.
                            <br />
                            <br />I et udlandsophold med en varighed på mere end 6 måneder, bliver
                            dage med ophold i DK under “ferie eller lignende”, der ligger længere
                            tilbage end 6 måneder frigivet. Dermed forlænges den tilladte
                            opholdsperiode i Danmark, svarende til forholdet mellem 6 måneder og 42
                            dage. Det bemærkes i den forbindelse, at der ikke stilles krav om, at
                            opholdet i udlandet er forlænget med en hel måned.
                            <br />
                            <br />
                            Læs evt. mere om emnet i “Cirkulære om ligningsloven” her:{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'underline' }}
                                href="https://www.retsinformation.dk/Forms/R0710.aspx?id=81195"
                            >
                                CIR nr. 72 af 17.4.1996
                            </a>
                            , pkt. 5.2.4. Afbrydelse af opholdet på grund af nødvendigt arbejde i
                            direkte forbindelse med udlandsopholdet, ferie el.lign.
                            <br />
                            <br />
                            ll33a.dk tæller dine dage i Danmark, som du angiver i kalenderen med
                            funktionen “+ Add DK-vacation”. Højeste antal dage i en løbende 6
                            måneders periode, vises nederst på kalender siden som; “Max: xx” for
                            hvert af dine udlandsophold. På Graf siden vises en grafisk oversigt af
                            dine dage i Danmark i relation til loftet på de 42 dage pr. løbende 6
                            måneder. Overskrider du 42-dages-reglen, afbrydes dit udlandsophold på
                            seneste ankomstdag i Danmark, der ligger forud for overskridelsesdatoen.
                        </p>
                    </div>
                    <SignupBelt includeLandingRef />
                    <Divider />
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="guide-start"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            Sådan indsætter du “ferie el.lign.” i DK
                        </h1>
                        <p className="landing-guide-header-p"></p>
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-1"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>
                                1. Gå til kalender-siden <CalendarOutlined />
                            </h1>
                            <p style={{ maxWidth: 400 }}>
                                Du starter et ferie ophold i Danmark under et udlandsophold på en
                                dato, hvor du ankommer Danmark.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 1"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s1.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-2"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>2. Tryk på ankomstsdagen til Danmark</h1>
                            <p style={{ maxWidth: 400 }}>
                                Naviger i kalenderen ved hjælp pilene {'<< >>'} for årstal, og{' '}
                                {'< >'} for måneder, frem til måneden for ankomst datoen. Tryk
                                herefter på dagen for din ankomst til Danmark.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 2"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s2.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-3"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>3. Tryk på “+ Add DK-vacation”</h1>
                            <p style={{ maxWidth: 400 }}>
                                Ankomst datoen er nu valgt og du mangler at angive udrejse dato for
                                ferieopholdet i Danmark.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 3"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s3.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-4"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>4. Tryk på afrejsedagen fra Danmark</h1>
                            <p style={{ maxWidth: 400 }}>
                                Naviger i kalenderen ved hjælp pilene {'<< >>'} for årstal, og{' '}
                                {'< >'} for måneder, frem til måneden for afrejsedatoen. Tryk
                                herefter på dagen for din afrejse fra Danmark. Du har nu indsat en
                                “ferie eller lignende” i Danmark.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 4"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s4.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-5"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>5. Nu er din ferie registreret</h1>
                            <p style={{ maxWidth: 400 }}>
                                Nu kan du se din ferie i kalenderen. I eksemplet er ferien indsat
                                uden for et udlandsophold, derfor er farven lyseblå. Hvis du har
                                indsat din ferie inden i et udlandsophold, vil ferien have samme
                                farve som udlandsopholdet.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 5"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s5.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-6"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>6. Rediger din “ferie el.lign.”</h1>
                            <p style={{ maxWidth: 400 }}>
                                Du redigerer din “ferie el.lign.” i kalenderen, ved at slette
                                opholdet i Danmark og herefter indsætte på ny.
                                <br />
                                Tryk på en dag i det “ferie el.lign” ophold i Danmark du ønsker
                                slettet. Tryk herefter på den røde knap “Delete DK-vacation” Du
                                indsætter nu det ønskede ophold som beskrevet i pkt. 2-4
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 6"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s6.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        id="step-7"
                    >
                        <h1 style={{ fontSize: 30 }}>
                            7. Grafen <FundOutlined />
                        </h1>
                        <p className="landing-guide-header-p">
                            Naviger til grafen (tryk på graf-ikonet i midten nederst på siden).
                            <br />
                            Grafen viser hvor mange dage du har været i Danmark i dit udlandsophold
                            ifh. til 42-dages-reglen. Har du flere udlandsophold, kan du vælge at se
                            dem alle i tidslinjen på grafen, ved at vælge “All Data”. Eller du kan
                            vælge at se hvert enkelt udlandophold individuelt.
                            <br />
                            <br />
                            Er et udlandsophold længere end 6 måneder, beregnes 6 måneder bagud for
                            hver dag i udlandsopholdet, hvor dagene med ophold i Danmark summeres op
                            og vises grafisk. Den røde linje markerer begrænsningen på de tilladte
                            42 dage pr. 6 måneder.
                            <br />
                            <br />
                            “Man kan forestille sig et rullende 6 måneders vindue, der flytter sig
                            en dag ad gangen inde i din udlandsperiode hvor dine dage med ophold i
                            Danmark opgøres.”
                            <br />
                            <br />
                            <span style={{ fontWeight: 'bold' }}>
                                “Day counter” finder du under grafen
                            </span>
                            <br />
                            Day counter tæller dine dage i Danmark fra{' '}
                            <span style={{ textDecoration: 'underline' }}>dags dato</span>,
                            (markeret med en sort lodret linje benævnt “Today”), og de seneste 6
                            måneder bagud, eller tilbage til start dato for udlandsopholdet, hvis
                            start dato for ligger mindre end 6 måneder tilbage. Tælleren vises med
                            grønne tal, men skifter til rød ved overskridelse af 42-dages-reglen. I
                            parentes vises antal dage, som du kan disponere over, frem til at du
                            igen får frigivet en dag. Antallet af dage fra “Today” og frem til at du
                            igen får frigivet en dag, beregnes under day counter. Dato vises i
                            parentes.
                            <br />
                            <br />
                            Med <span style={{ fontWeight: 'bold' }}>Daycount across periods</span>,
                            kan du tilvælge at se en grafisk visning af dage i Danmark, inklusiv
                            dage som ligger uden for dine udlandsperioder. Disse vises nu med en
                            mørk graf oven på den grønne graf. Bemærk, den mørke graf tæller ud og
                            hjem-rejse dagene for udlandsophold med.
                            <br />
                            <br />
                            <span style={{ fontWeight: 'bold' }}>
                                Tooltip’et på grafen viser information om datoen
                            </span>
                            <br />
                            Før musen over på grafen og se:
                            <br />
                            -dato
                            <br />
                            -antal dage i DK i denne udlandsperiode indtil denne valgte dato
                            <br />
                            -om pågældende dag var i DK
                            <br />
                            -om du har angivet en note
                            <br />
                            <br />
                            <span style={{ fontWeight: 'bold' }}>
                                Nederst på Graf-siden er dine udlandsophold angivet
                            </span>
                            <br />
                            “Max: tælleren” viser det højste antal dage du har lagt i Danmark i en
                            enhver løbende 6 måneders periode for det pågældende udlandsophold.
                            Dette er inklusiv dine fremtidige planlagte dage i Danmark i en
                            indeværende udlandsperiode.
                        </p>
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="step-7"
                    >
                        <Img
                            title="Guide Image"
                            alt="step 7"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s7.childImageSharp.fluid}
                        />
                    </div>
                    <SignupBelt includeLandingRef />
                </div>
            </span>
        </Container>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "guide-assets/42-regel-step-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s2: file(relativePath: { eq: "guide-assets/42-regel-step-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s3: file(relativePath: { eq: "guide-assets/42-regel-step-3.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s4: file(relativePath: { eq: "guide-assets/42-regel-step-4.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s5: file(relativePath: { eq: "guide-assets/42-regel-step-5.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s6: file(relativePath: { eq: "guide-assets/42-regel-step-6.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s7: file(relativePath: { eq: "guide-assets/42-regel-step-7.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s8: file(relativePath: { eq: "guide-assets/42-regel-step-8.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
